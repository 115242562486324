import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  Content,
  Team,
  Reviews,
  CustomerReviewModal,
} from "@bluefin/components";
import { Header, List, Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"about-hero"}>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"about-page"}
        >
          <Content className={"about-content"}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>About Us</Header>
              <List horizontal={true} bulleted={true}>
                <List.Item as={"a"} href={"#our-mission"}>
                  Our Mission
                </List.Item>
                <List.Item as={"a"} href={"#our-values"}>
                  Our Values
                </List.Item>
                <List.Item as={"a"} href={"#our-commitment-to-safety"}>
                  Our Commitment To Safety
                </List.Item>
                <List.Item as={"a"} href={"#our-commitment-to-quality"}>
                  Our Commitment To Quality
                </List.Item>
                <List.Item as={"a"} href={"#meet-our-team"}>
                  Meet Our Team
                </List.Item>
              </List>
            </Content.Markup>
          </Content>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <Grid
                stackable={true}
                padded={true}
                columns={"2"}
                text-align={"center"}
              >
                <Grid.Column>
                  <Image
                    src={
                      "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/About_1.png"
                    }
                    centered={true}
                  />
                </Grid.Column>
                <Grid.Column>
                  <div>
                    <div id={"our-mission"}>
                      <h2>OUR MISSION</h2>
                      <p>
                        Our culture is based on having a love and appreciation
                        for animals. From the moment you walk in you see our Pet
                        Stylist’s hard at work! We believe in being 100%
                        transparent which is why our groomers don’t hide behind
                        a wall. We don’t hold back in providing the best
                        shampoos and conditioners ranging from scented,
                        medicated, soothing, mud treatments and more. We aim to
                        provide a true spa experience for our clients. We are
                        not just a typical grooming salon. Our passion for
                        animals is what allows us to easily commit the best
                        possible experience! Our team is built by finding
                        like-minded people that share in that passion.
                      </p>
                    </div>
                    <div id={"our-values"}>
                      <h2>OUR VALUES</h2>
                      <List
                        items={[
                          "P.A.W.S.",
                          "P - Provide the safest environment for pet guests at all times",
                          "A - Always be 100% transparent with our customers",
                          "W - Win over our pet parents and pet guests by building relationships",
                          "S – Offer and recommend the right services by completing our SCENT 5 point check in",
                        ]}
                      />
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
            className={"background-gray"}
            id={"our-commitment-to-safety"}
          >
            <Grid.Column width={16}>
              <Grid
                stackable={true}
                padded={true}
                columns={"2"}
                text-align={"center"}
                vertical-align={"middle"}
              >
                <Grid.Column>
                  <h2>OUR COMMITMENT TO SAFETY</h2>
                  <p>
                    The safety of your beloved pup is our top priority. We
                    promise to provide them a safe and enjoyable grooming
                    experience. Here are some of the many ways we ensure your
                    pup is safe and sound:
                  </p>
                  <h3>S.C.E.N.T. 5 POINT CHECK IN</h3>
                  <p>
                    Upon arrival your Pet Stylist will introduce themselves and
                    perform a 5 point check in. This consists of checking their
                    skin condition, coat condition, eyes, ears, nails and teeth.
                    We are looking for any health conditions that we need to be
                    aware of as well as anything that may need to be recommended
                    to do during their spa visit. This process allows you and
                    your Pet stylist to discuss any special needs or any
                    recommendations needed for your pet in order to provide the
                    best experience.
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <Image
                    src={
                      "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/About_2.png"
                    }
                    centered={true}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
            className={"background-pink"}
          >
            <Grid.Column width={16}>
              <Grid
                stackable={true}
                padded={true}
                columns={"1"}
                text-align={"center"}
                verticalAlign={"middle"}
              >
                <Grid.Column>
                  <h3>PRE-EXISTING HEALTH CONDITIONS</h3>
                  <p>
                    It is important to keep us aware of any pre-existing
                    conditions that may affect our ability to provide your pup
                    any of our services, including heart murmurs, seizures, hot
                    spots, skin diseases, etc.
                  </p>
                  <p>
                    If at any point your pup experiences unsafe levels of
                    distress, we may choose to stop the service for the safety
                    of the pup and the groomer. If your pup’s health condition
                    could prove to be a high risk for the level of service
                    requested, we may choose to refuse to provide the service at
                    no penalty to the pet parent.
                  </p>
                  <p>
                    We will NEVER recommend a groom or cut that would cause pain
                    or discomfort to your pup. We are confident and comfortable
                    with guiding pet parents in choosing the right pattern and
                    length for their pup’s coat, based on the current condition
                    of the coat.
                  </p>
                  <p>
                    Our tools are high-quality, well-maintained and regularly
                    sharpened. We will NEVER use dull clippers, dull shears or
                    any unsafe tools on your pup to get the service done. We
                    will NEVER pull, tug or stretch your pup in a way that could
                    cause injury or accidental cuts and burns. We will NEVER
                    take our eyes off of your pup, especially while on the table
                    or in the tub. Your pup is in good hands!
                  </p>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
            id={"our-commitment-to-quality"}
            className={"background-gray"}
          >
            <Grid.Column width={16}>
              <Grid
                stackable={true}
                padded={true}
                columns={"2"}
                text-align={"center"}
                verticalAlign={"middle"}
              >
                <Grid.Column>
                  <Image
                    src={
                      "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/About_3.png"
                    }
                    centered={true}
                    size={"huge"}
                  />
                </Grid.Column>
                <Grid.Column>
                  <h2>OUR COMMITMENT TO QUALITY</h2>
                  <p>
                    Pet grooming is what we do best and we take pride in
                    achieving EXCELLENCE. We want your pup to look, smell and
                    feel so good that both you and your fur baby are excited to
                    come visit us again. Our goal is to deliver a 5-star service
                    each and every time, and we hope that you will rave about us
                    to family and friends so we can make more pups look and feel
                    their best! Here are some of the ways we ensure our service
                    is second to none:
                  </p>
                  <h4>OUR PRODUCTS</h4>
                  <p>
                    We do not bathe our own pups in cheap products, and we
                    wouldn’t use cheap products on your pups either. It’s not
                    enough to get a shampoo or conditioner that gets the job
                    done. We want you to see, smell and feel REAL results.
                  </p>
                  <h4>OUR STANDARDS</h4>
                  <p>
                    Lorina our founder, is the heart of Nails 2 Tails and sets a
                    very high bar when it comes to the care and grooming of your
                    pet children. As a team we take great pride in our
                    reputation and always strive to be above the rest. With our
                    extensive experience on breed patterns, styles and coat
                    types, we can make any pup look and feel like a superstar.
                  </p>
                  <h4>OUR TOOLS & METHODS</h4>
                  <p>
                    We LOVE high-quality tools and spend lots of money to own
                    them. We are meticulous in how we decide which shears and/or
                    clippers to use to achieve the results we want for your pup.
                    We do not cut corners or rush any aspect of the bathing and
                    grooming process because we believe that every step is
                    critical to giving your pup the coat they deserve.
                  </p>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container team-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
            id={"meet-our-team"}
          >
            <Grid.Column width={14}>
              <h1 className={"our-team-title"}>Meet Our Team</h1>
              <p>
                Our team was built by finding like-minded people that have a
                strong passion for animals. We don’t only look for experience
                when we interview, it is just as important to us to know you are
                a true dog lover. Every one of our team members has true passion
                for animals which is why we strive to provide the best
                experience possible for you and your fur baby.
              </p>
              <Team
                team={allFishermanBusinessTeam.nodes}
                as={"verticalItem"}
                itemsPerRow={3}
                header={""}
              />
            </Grid.Column>
          </Grid>
          <div className={"reviews-container"}>
            <Reviews
              reviews={fishermanBusiness.reviews}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={"Customer Reviews"}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
            <CustomerReviewModal
              businessId={fishermanBusiness._id}
              locations={allFishermanBusinessLocation.nodes}
              businessType={fishermanBusiness.type}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        author
        text
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessTeam(filter: { show: { eq: true } }) {
      nodes {
        _id
        name
        role
        group
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        show
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
